<template>
  <div class="cards">
    <page-header
      :title="title"
      :subtitle="subtitle"
      @startDate="getPersonaliseStartDate"
      @endDate="getPersonaliseEndDate"
      @filterOptionSelected="filterOptionSelected"
    />
    <div class="contain-separator-section-end-content w-full">
      <div class="separator-section w-full flex mt-6">
        <div
          class="flex-one p-2 font-bold name cursor-pointer"
          :class="{ 'is-color': !isSMS }"
          @click="showInApp"
        >
          {{ $t('campaignInApp') }}
          <svg-icon
            slot="reference"
            v-tippy
            :content="$t('campaignInAppTooltip')"
            name="ic-infos"
            class="w-4 h-4 ml-2"
            original
          />
        </div>
        <div
          class="flex-one  name  p-2 font-bold cursor-pointer"
          :class="{ 'is-color': isSMS }"
          @click="showInSms"
        >
          {{ $t('campaignSms') }}
          <svg-icon
            slot="reference"
            v-tippy
            :content="$t('campaignSmsTooltip')"
            name="ic-infos"
            class="w-4 h-4 ml-2"
            original
          />
        </div>
      </div>
      <div
        v-if="!isSMS"
        class="page_content"
      >
        <div class="flex font-bold uppercase py-6">
          <h5 class="w-3/5 text-sm">
            {{ $t('campaignName') }}
          </h5>
          <h5 class="w-1/6 text-sm text-left">
            {{ $t('campaignViews') }}
          </h5>
          <h5 class="w-1/6 text-sm text-left">
            {{ $t('campaignClic') }}
          </h5>
          <h5 class="w-1/5 text-sm text-left">
            {{ $t('campaignBudget') }}
            <svg-icon
              slot="reference"
              v-tippy
              :content="$t('portPopup')"
              name="ic-infos"
              class="w-3 h4"
              original
            />
          </h5>
          <!-- <h5 class="w-1/6 text-sm text-left">
            {{ $t('campaignCpc') }}
            <svg-icon
              slot="reference"
              v-tippy
              :content="$t('costClick')"
              name="ic-infos"
              class="w-3 h4"
              original
            />
          </h5> -->
          <!-- <h5 class="w-1/6 text-sm text-left">
            {{ $t('campaignCpv') }}
            <svg-icon
              slot="reference"
              v-tippy
              :content="$t('costView')"
              name="ic-infos"
              class="w-3 h4"
              original
            />
          </h5> -->
          <h5 class="w-1/5 text-sm text-left">
            {{ $t('campaignBegin') }}
          </h5>
          <h5 class="w-1/6 text-sm text-left">
            {{ $t('campaignDuration') }}
            <svg-icon
              v-tippy
              :content="$t('durationInDayLabelThree') + '. ' + $t('durationInDayLabelTwo')"
              name="ic-infos"
              class="w-3 h4"
              original
            />
          </h5>
          <h5 class="w-1/6 text-sm text-left" />
        </div>
        <div
          v-if="getAllCampaign.length !== 0"
          class="campagne-list"
        >
          <CampaignList
            v-for="(item, key) in getAllCampaign"
            :key="item+key"
            :campaign="item"
            :pos="key"
            @deletePost="getAction"
          />
        </div>
        <div
          v-if="getAllCampaign.length === 0"
          class="none-campaigne "
        >
          <div class="icon-section text-center">
            <svg-icon
              name="ic-campaign"
              class="w-16 h-16"
              color="#D8D8D8"
            />
          </div>
          <h3 class="text-center mt-3 text-xl font-light none-label">
            {{ $t('noneCampaign') }}
          </h3>
        </div>
      </div>
      <div
        v-if="isSMS"
        class="page_content"
      >
        <div class="flex font-bold uppercase py-6">
          <h5 class="w-full text-sm">
            {{ $t('campaignName') }}
          </h5>
          <h5 class="w-1/3 text-sm text-center">
            {{ $t('sendSMS') }}
          </h5>
          <!-- <h5 class="w-1/3 text-sm text-center">
            {{ $t('campaignBudget') }}
            <svg-icon
              slot="reference"
              v-tippy
              :content="$t('portPopup')"
              name="ic-infos"
              class="w-3 h4"
              original
            />
          </h5> -->
          <h5 class="w-1/3 text-sm text-center">
            {{ $t('difusion') }}
          </h5>
          <h5 class="w-1/6 text-sm text-left" />
        </div>
        <div
          v-if="getAllCampaign.length !== 0"
          class="campagne-list"
        >
          <SmsCampaign
            v-for="(item, key) in getAllCampaign"
            :key="item+key"
            :campaign="item"
            :pos="key"
            @deletePost="getAction"
          />
        </div>
        <div
          v-if="getAllCampaign.length === 0"
          class="none-campaigne "
        >
          <div class="icon-section text-center">
            <svg-icon
              name="ic-campaign"
              class="w-16 h-16"
              color="#D8D8D8"
            />
          </div>
          <h3 class="text-center mt-3 text-xl font-light none-label">
            {{ $t('noneCampaign') }}
          </h3>
        </div>
      </div>
    </div>
    <my-custom-loader v-if="showLoader" />
  </div>
</template>

<script>
import PageHeader from '../../components/helper/rema-components/page-header'
import CampaignList from '../../components/helper/rema-components/campaign-list'
import firebase from 'firebase'
import { firebaseDb } from '../../main'
import { firebaseRef } from '../../rema-function/firebaseRef'
import moment from 'moment'
import MyCustomLoader from '../../components/helper/rema-components/my-custom-loader'
import SmsCampaign from '../../components/helper/rema-components/sms-campaigns'
import store from '../../store'
export default {
  name: 'HomeCards',
  components: {
    SmsCampaign,
    MyCustomLoader,
    PageHeader,
    CampaignList
  },
  props: {
    msg: String
  },
  data () {
    return {
      isSMS: false,
      title: this.$t('campaigns'),
      subtitle: this.$t('campaignSubtitle'),
      allCampaign: [],
      draftedCampaign: [],
      invalidatedCampaign: [],
      selectedOption: 'thirty',
      startDateForPersonalise: '',
      endDateForPersonalise: '',
      newSmsAll: [],
      mainSmsExit: false,
      newSmsDraft: [],
      newSmsDraftExist: false,
      invalidatedSms: [],
      invalidatedSmsExist: false,
      newAll: [],
      mainExist: false,
      newDraft: [],
      draftExit: false,
      newInvalidate: [],
      invalidateExist: false,
      allAreReady: [],
      allSmsAreReady: [],
      showLoader: false,
      showAction: false
    }
  },
  firebase: function () {
    return {
      // allCampaign: firebaseDb.ref(firebaseRef.mainsAdsFeeds).orderByChild('author').equalTo(firebase.auth().currentUser.uid),
      // draftedCampaign: firebaseDb.ref(firebaseRef.draftAds).orderByChild('author').equalTo(firebase.auth().currentUser.uid),
      //  invalidatedCampaign: firebaseDb.ref(firebaseRef.invalidatedAds).orderByChild('author').equalTo(firebase.auth().currentUser.uid)
    }
  },
  computed: {
    getAllCampaign: function () {
      let allCampaign = this.allAreReady
      let realCampaign = []
      console.log('taille', allCampaign)
      if (this.selectedOption === '') {
        realCampaign = allCampaign
      } if (this.selectedOption === 'personalize') {
        alert('personalize')
        realCampaign = []
        let startTimestamp = new Date(this.startDateForPersonalise).getTime()
        let endTimestamp = new Date(this.endDateForPersonalise).getTime()
        for (let i = 0; i < allCampaign.length; i++) {
          let current = allCampaign[i]
          // alert(current.postedAtDate)
          let mo = moment(current.postedAtDate, 'DD/MM/YYYY')
          let currentDate = new Date(mo).getTime()
          if (startTimestamp <= currentDate && currentDate <= endTimestamp && !current.isDelete) {
            realCampaign.push(current)
          }
        }
      } else {
        realCampaign = []
        let lastTimestamp = this.generateLastDate(this.generateNumberSubtractDays(this.selectedOption))
        // let today = new Date(new Date().getTime() + 48 * 60 * 60 * 1000)
        for (let i = 0; i < allCampaign.length; i++) {
          let current = allCampaign[i]
          // alert(current.postedAtDate)
          let mo = moment(current.postedAtDate, 'DD/MM/YYYY')
          let currentDate = new Date(mo).getTime()
          if (lastTimestamp <= currentDate && !current.isDelete) {
            realCampaign.push(current)
          }
        }
      }
      let reorder = []
      for (let i = 0; i < realCampaign.length; i++) {
        if (typeof realCampaign[i] !== 'undefined') {
          reorder.unshift(realCampaign[i])
        }
      }
      return realCampaign.sort(function (a, b) {
        let ma = moment(a.postedAtDate, 'DD/MM/YYYY')
        let ab = new Date(ma).getTime()
        let mb = moment(b.postedAtDate, 'DD/MM/YYYY')
        let ba = new Date(mb).getTime()
        return ba - ab
      })
    },
    getState () {
      return store.getters.getState
    }
  },
  watch: {
    mainExist: function () {
      this.readyAlert()
    },
    draftExit: function () {
      this.readyAlert()
    },
    invalidateExist: function () {
      this.readyAlert()
    },
    isSMS: function () {
      this.readyAlert()
    },
    mainSmsExit: function () {
      this.readyAlert()
    },
    newSmsDraftExist: function () {
      this.readyAlert()
    },
    invalidatedSmsExist: function () {
      this.readyAlert()
    }
  },
  created () {
    // alert(firebase.auth().currentUser.uid)
    let self = this
    self.showLoader = true
    firebaseDb.ref(firebaseRef.mainsAdsFeeds).orderByChild('author').equalTo(firebase.auth().currentUser.uid)
      .once('value', function (snapshot) {
        if (snapshot.exists()) {
          let i = 0
          let lenght = snapshot.numChildren()
          snapshot.forEach(function (sss) {
            self.newAll.push(sss.val())
            i++
            if (i === lenght) {
              self.mainExist = true
            }
          })
        } else {
          self.mainExist = true
        }
      })
    firebaseDb.ref(firebaseRef.draftAds).orderByChild('author').equalTo(firebase.auth().currentUser.uid)
      .once('value', function (snapshot) {
        if (snapshot.exists()) {
          let i = 0
          let lenght = snapshot.numChildren()
          snapshot.forEach(function (sss) {
            self.newDraft.push(sss.val())
            i++
            if (i === lenght) {
              self.draftExit = true
            }
          })
        } else {
          self.draftExit = true
        }
      })
    firebaseDb.ref(firebaseRef.invalidatedAds).orderByChild('author').equalTo(firebase.auth().currentUser.uid)
      .once('value', function (snapshot) {
        if (snapshot.exists()) {
          let i = 0
          let lenght = snapshot.numChildren()
          snapshot.forEach(function (sss) {
            self.newInvalidate.push(sss.val())
            i++
            if (i === lenght) {
              self.invalidateExist = true
            }
          })
        } else {
          self.invalidateExist = true
        }
      })
    firebaseDb.ref(firebaseRef.mainsSMSAdsFeeds).orderByChild('author').equalTo(firebase.auth().currentUser.uid)
      .once('value', function (snapshot) {
        if (snapshot.exists()) {
          let i = 0
          let lenght = snapshot.numChildren()
          snapshot.forEach(function (sss) {
            self.newSmsAll.push(sss.val())
            i++
            if (i === lenght) {
              self.mainSmsExit = true
            }
          })
        } else {
          self.mainSmsExit = true
        }
      })
    firebaseDb.ref(firebaseRef.draftSMSFeeds).orderByChild('author').equalTo(firebase.auth().currentUser.uid)
      .once('value', function (snapshot) {
        if (snapshot.exists()) {
          let i = 0
          let lenght = snapshot.numChildren()
          snapshot.forEach(function (sss) {
            self.newSmsDraft.push(sss.val())
            i++
            if (i === lenght) {
              self.newSmsDraftExist = true
            }
          })
        } else {
          self.newSmsDraftExist = true
        }
      })
    firebaseDb.ref(firebaseRef.invalidedSMS).orderByChild('author').equalTo(firebase.auth().currentUser.uid)
      .once('value', function (snapshot) {
        if (snapshot.exists()) {
          let i = 0
          let lenght = snapshot.numChildren()
          snapshot.forEach(function (sss) {
            self.invalidatedSms.push(sss.val())
            i++
            if (i === lenght) {
              self.invalidatedSmsExist = true
            }
          })
        } else {
          self.invalidatedSmsExist = true
        }
      })
    this.isSMS = this.getState
  },
  methods: {
    justText () {
      firebaseDb.ref(firebaseRef.users).child('qwertyuiopasdfghjk').set({
        fulname: 'Ali'
      })
        .then(() => {
          // alert('terminer')
        })
        .catch((err) => {
          alert(err.toString())
        })
    },
    readyAlert () {
      if (this.mainExist && this.draftExit && this.invalidateExist && this.mainSmsExit && this.newSmsDraftExist && this.invalidatedSmsExist) {
        if (!this.isSMS) {
          let rallAreReady = this.newAll.concat(this.newDraft, this.newInvalidate)
          //   alert('iciicic')
          let real = []
          for (let i = 0; i < rallAreReady.length; i++) {
            if (typeof rallAreReady[i].postInfo !== 'undefined') {
              real.push(rallAreReady[i])
            }
          }
          console.log(real)
          this.allAreReady = real
          this.showLoader = false
          console.log(this.allAreReady)
        } else {
          let rallAreReady = this.newSmsAll.concat(this.newSmsDraft, this.invalidatedSms)
          //   alert('iciicic')
          let real = []
          for (let i = 0; i < rallAreReady.length; i++) {
            if (typeof rallAreReady[i].postInfo !== 'undefined') {
              real.push(rallAreReady[i])
            }
          }
          console.log(real)
          this.allAreReady = real
          this.showLoader = false
          console.log(this.allAreReady)
        }
      }
    },
    generateRealPost () {
      let defaultCampain = this.allCampaign.concat(this.draftedCampaign, this.invalidatedCampaign)
      let real = []
      for (let i = 0; i < defaultCampain.length; i++) {
        if (typeof defaultCampain[i].postInfo !== 'undefined') {
          real.push(defaultCampain[i])
        }
      }
      return real
    },
    filterOptionSelected (option) {
      this.selectedOption = option.value
    },
    generateNumberSubtractDays (value) {
      if (value === 'thirty') {
        return 30
      }
      if (value === 'three') {
        return 90
      }
      if (value === 'six') {
        return 180
      }
      if (value === 'twelve') {
        return 360
      } else {
        return 0
      }
    },
    generateLastDate (days) {
      // Days you want to subtract
      let date = new Date()
      let last = new Date(date.getTime() - ((days) * 24 * 60 * 60 * 1000)).getTime()
      return last
    },
    getPersonaliseStartDate (answer) {
      this.startDateForPersonalise = answer
    },
    getPersonaliseEndDate (answer) {
      this.endDateForPersonalise = answer
    },
    getAction (answer) {
      alert(answer)
      this.getAllCampaign[answer].isDelete = true
      this.getAllCampaign[answer].isVisible = false
      this.getAllCampaign[answer].isActive = false
    },
    showInApp () {
      this.isSMS = false
      this.$store.dispatch('createState', false)
    },
    showInSms () {
      this.isSMS = true
      this.$store.dispatch('createState', true)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/style/sass/variables";
  .cards{
    .popper {
      box-shadow: none;
      background-color: white;
      padding: 1rem;
      border-radius: 10px;
      color: black;
      font-size: .7rem;
      text-transform: none;
    }
    .campagne-section {
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow: auto;
    }
    .none-label {
      color: #D8D8D8;
    }
    .none-campaigne {
      margin-top: 10%;
    }
  }
  .is-color {
    color: $Green-Rema;
    border-bottom: solid 1px $Green-Rema;
  }
  .name {
    width: 15%;
    text-align: center;
  }
</style>
