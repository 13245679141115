<template>
  <div v-if="campaign.isDelete !== true">
    <!--
      @click="wantToSeeDetail = !wantToSeeDetail"
-->
    <div
      class="cursor-pointer  campaingn-item   rounded-lg mt-1"
      @click="wantToSeeDetail = !wantToSeeDetail"
    >
      <div class="flex-section bg-white p-2 flex w-full bg-white">
        <div class="titleBloc flex flex-one w-full">
          <div
            class="image-block  h-full  flex justify-center  rounded"
          >
            <svg-icon
              name="sms"
              class="h-10 w-10 mt-5 text-center"
              original
            />
          </div>
          <div class="pl-2">
            <h3 class="font-normal mt-3 camp-title text-capitalize capitalize">
              {{ campaign.title }}
            </h3>
            <h3
              v-if="getPostType(campaign) === 'progress'"
              class="text-center px-3 mt-3  active-state text-white py-2 bg-red rounded-full text-sm"
            >
              {{ $t('campaignValideted') }}
            </h3>
            <h3
              v-if="getPostType(campaign) === 'ended'"
              class="text-center px-3 mt-3  statusFinish text-white py-2 bg-red rounded-full text-sm"
            >
              {{ $t('campaignValideted') }}
            </h3>
            <h3
              v-if="getPostType(campaign) === 'wait'"
              class="text-center px-3 mt-3  statusWaiting text-white py-2 bg-red rounded-full text-sm"
            >
              {{ $t('campaignStatusWaiting') }}
            </h3>
            <h3
              v-if="getPostType(campaign) === 'inactive'"
              class="text-center px-3 mt-3  statusInvalid  text-white py-2 bg-red rounded-full text-sm"
            >
              {{ $t('campaignStatusInvalid') }}
            </h3>
            <h3
              v-if="getPostType(campaign) === 'draft'"
              class="text-center px-3 mt-3  statusDraft  text-white py-2 bg-red rounded-full text-sm"
            >
              {{ $t('campaignStatusDraft') }}
            </h3>
          </div>
        </div>
        <div class="nbre-vue flex-one w-1/6 font-light justify-center py-8 hidden">
          <span v-if="getPostType(campaign) !== 'draft'"> {{ Math.round(postViewers(viewerTab).length * logRema(postViewers(viewerTab).length)) }}</span>
          <span v-if="getPostType(campaign) === 'draft'"> 0 </span>
        </div>
        <div class="nbre-clic flex-one w-1/6 font-light justify-center py-8 hidden">
          <span v-if="getPostType(campaign) !== 'draft'">{{ Math.round(postClickers(clickerTab).length * logRema(postClickers(clickerTab).length)) }}</span>
          <span v-if="getPostType(campaign) === 'draft'">0</span>
        </div>
        <div class="nbre-budget flex-one w-1/5 font-light justify-center py-8 hidden">
          {{ convertToEuro(campaign.postCout, campaign.deviseMoney) }} €
        </div>
        <div class="nbre-cpc flex-one w-1/6 font-light justify-center py-8 hidden">
          <span v-if="getPostType(campaign) !== 'draft'"> {{ costPerClick(convertToEuro(campaign.postCout, campaign.deviseMoney), Math.round(postClickers(clickerTab).length * logRema(postClickers(clickerTab).length)) ) }} €</span>
          <span v-if="getPostType(campaign) === 'draft'">0 €</span>
        </div>
        <div class="nbre-cpv flex-one w-1/3 font-light justify-center  hidden">
          <span v-if="getPostType(campaign) !== 'draft'">{{ costPerViews(convertToEuro(campaign.postCout, campaign.deviseMoney), Math.round(postViewers(viewerTab).length * logRema(postViewers(viewerTab).length)) ) }} €</span>
          <span v-if="getPostType(campaign) === 'draft'">  0 € </span>
        </div>
        <div class="date-debut flex-one w-1/3 font-light justify-center  hidden py-8">
          {{ campaign.postedAtDate }}
        </div>
        <div class="duree flex-one w-1/3 text-center font-light hidden justify-center py-8">
          {{ campaign.postDuree }}
        </div>
        <h5 class="w-1/3 text-sm font-light text-center py-8">
          {{ campaign.porteeProbable }}
        </h5>
        <h5 class="w-1/3 py-8 text-sm font-light text-center hidden">
          {{ convertToEuro(campaign.postCout, campaign.deviseMoney) }} €
        </h5>
        <h5 class="w-1/3 py-8 text-sm font-light text-center">
          {{ campaign.postedAtDate }}
        </h5>
        <div
          class="more cursor-pointer flex-one w-1/6 font-light text-right justify-center py-8"
          @click.stop="wantToSeeAction = !wantToSeeAction"
        >
          <svg-icon
            name="ic-more"
            class="w-3 h3"
            original
          />
        </div>
      </div>
    </div>
    <div
      v-if="wantToSeeAction"
      class="list  p-2 relative float-right rounded mr-12 shadow"
    >
      <h3
        class="p-2 cursor-pointer font-normal"
        @click="showPreview"
      >
        <spam>
          <svg-icon
            name="ic-action-see"
            class="w-4 h-4"
            original
          />
        </spam>
        <span class="ml-2">
          {{ $t('see') }}
        </span>
      </h3>
      <h3
        v-if="getPostType(campaign) === 'progress'"
        class="p-2 cursor-pointer font-normal hidden"
        @click="pausedPost"
      >
        <spam>
          <svg-icon
            name="ic-action-pause"
            class="w-4 h-4"
            original
          />
        </spam>
        <span class="ml-2">
          {{ $t('inPause') }}
        </span>
      </h3>
      <h3
        v-if="getPostType(campaign) === 'suspended'"
        class="p-2 cursor-pointer font-normal hidden"
        @click="restartPaused"
      >
        <spam>
          <svg-icon
            name="ic-restart"
            class="w-4 h-4"
            original
          />
        </spam>
        <span class="ml-2">
          {{ $t('reStart') }}
        </span>
      </h3>
      <h3
        v-if="getPostType(campaign) === 'progress' || getPostType(campaign) === 'ended' || getPostType(campaign) === 'draft' || getPostType(campaign) === 'wait' || getPostType(campaign) === 'inactive'"
        class="p-2 cursor-pointer font-normal"
        @click="reUsePost"
      >
        <spam>
          <svg-icon
            :name="getSpeIcon(campaign)"
            class="w-4 h-4"
            original
          />
        </spam>
        <span class="ml-2">
          {{ getSpeLabel(campaign) }}
        </span>
      </h3>
      <h3
        class="p-2 cursor-pointer text-red font-normal"
        @click="deletepost"
      >
        <spam>
          <svg-icon
            name="ic-action-del"
            class="w-4 h-4"
            original
          />
        </spam>
        <span class="ml-2">
          {{ $t('deletePerm') }}
        </span>
      </h3>
    </div>
    <div
      v-if="wantToSeeDetail"
      class="details-section bg-white p-4 mb-1 rounded"
    >
      <div class="my-divider  w-full -mt-5" />
      <div class="pt-3">
        <div class="flex pt-3">
          <div class=" w-1/2 flex-one country-result">
            <h3 class="text-xl font-normal mt-2">
              {{ $t('countryResult') }}
            </h3>
            <div class="country-list border w-1/2  rounded p-2 mt-3">
              <div class="header border-b p-2 flex">
                <div class="flex-one w-1/2">
                  {{ $t('country') }}
                </div>
                <div class="flex-one w-1/2 text-center">
                  {{ $t('sendSMS') }}
                </div>
                <!--   <div class="flex-one w-1/4 text-center">
                  {{ $t('campaignClic') }}
                </div>
                <div class="flex-one w-1/4 text-center">
                  {{ $t('campaignCpc') }}
                </div>
                <div class="flex-one w-1/4 text-center">
                  {{ $t('campaignCpv') }}
                </div> !-->
              </div>
              <div class="mt-2" />
              <div
                v-for="(item, key) in campaign.paysCible"
                :key="item+key"
                class="content  p-2 flex"
              >
                <div class="flex-one w-1/2">
                  {{ item.text }}
                </div>
                <div
                  class="flex-one w-1/2 text-center"
                >
                  {{ Math.round(countryMatchSms(item.callingCodes) * (getCoof())) }}
                </div>
                <!--<div class="flex-one w-1/4 text-center">
                  {{ countryMathClickers(item.callingCodes) }}
                </div>
                <div class="flex-one w-1/4 text-center">
                  {{ costPerViews(convertToEuro(campaign.postCout, campaign.deviseMoney),countryMathClickers(item.callingCodes)) }} €
                </div>
                <div class="flex-one w-1/4 text-center">
                  {{ costPerViews(convertToEuro(campaign.postCout, campaign.deviseMoney),countryMatch(item.callingCodes)) }} €
                </div> !-->
              </div>
            </div>
          </div>
          <div class=" w-1/2 flex-one chart-section ml-4">
            <h3 class="text-xl font-normal mt-2">
              {{ $t('seeAll') }}
            </h3>
            <div class="section-chart">
              <MapChart
                :country-data="countryDataViews"
                high-color="#16B853"
                low-color="#ffffff"
                country-stroke-color="#000000"
                default-country-fill-color="#ffffff"
              />
              <div class="float-right legend-section mr-3">
                <h3 class="flex m-1">
                  <h3 class="flex-one rounded big-legend icon-legend h-6 w-1/2 " />
                  <h3 class="flex-one  w-full  text-sm  font-light ml-1 mt-1 ">
                    {{ Math.floor((getMaxVal/2)) + 1 }} - {{ getMaxVal }}
                  </h3>
                </h3>
                <h3 class="flex m-1">
                  <h3 class="flex-one rounded small-legend icon-legend h-6 w-1/2 " />
                  <h3 class="flex-one  w-full  text-sm  font-light ml-1 mt-1 ">
                    1 - {{ Math.floor((getMaxVal/2)) }}
                  </h3>
                </h3>
                <h3 class="flex m-1">
                  <h3 class="flex-one border rounded none-legend icon-legend h-6 w-1/2 " />
                  <h3 class="flex-one  w-full  text-sm  font-light ml-1 mt-1">
                    0 - 0
                  </h3>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sms-campaign-preview
      v-if="wantToSeePreview"
      :campaign="campaign"
      :image-url="image"
      @emitCloseBlock="emitCloseBlock"
    />
  </div>
</template>

<script>
import firebaseStorage from '../../../rema-function/firebaseStorage'
import { firebaseDb } from '../../../main'
import { firebaseRef } from '../../../rema-function/firebaseRef'
import { remaDefaultData } from '../../../rema-function/remaDefaultData'
import moment from 'moment'
import firebaseDatabase from '../../../rema-function/firebaseDatabase'
import MapChart from 'vue-map-chart'
import SmsCampaignPreview from './sms-campaign-preview'
export default {
  name: 'SmsCampaigns',
  components: { SmsCampaignPreview, MapChart },
  props: {
    campaign: {
      type: Object,
      default: function () {
        return {}
      }
    },
    pos: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      image: '',
      wantToSeeAction: false,
      suspendButton: 'red',
      deleteColor: 'red',
      wantToSeeDetail: false,
      viewCounting: {},
      viewerTab: [],
      clickerTab: [],
      clickCount: {},
      wantToSeePreview: false,
      viewers: [],
      allCountryUser: [],
      clickers: [],
      allClickersCountry: [],
      countryDataViews: {
        AO: 0,
        BF: 0,
        BI: 0,
        BJ: 0,
        BW: 0,
        CD: 0,
        CF: 0,
        CG: 0,
        CI: 0,
        CM: 0,
        DJ: 0,
        DZ: 0,
        EG: 0,
        EH: 0,
        ER: 0,
        ET: 0,
        GA: 0,
        GH: 0,
        GM: 0,
        GN: 0,
        GQ: 0,
        GW: 0,
        KE: 0,
        LR: 0,
        LS: 0,
        LY: 0,
        MA: 0,
        MG: 0,
        ML: 0,
        MR: 0,
        MW: 0,
        MZ: 0,
        NA: 0,
        NE: 0,
        NG: 0,
        RW: 0,
        SD: 0,
        SL: 0,
        SN: 0,
        SO: 0,
        SS: 0,
        TD: 0,
        TG: 0,
        TN: 0,
        TZ: 0,
        UG: 0,
        ZA: 0,
        ZM: 0,
        ZW: 0
      }
    }
  },
  computed: {
    allCountryArrat () {
      return [
        this.countryDataViews.BJ,
        this.countryDataViews.DZ,
        this.countryDataViews.AO,
        this.countryDataViews.BW,
        this.countryDataViews.BF,
        this.countryDataViews.BI,
        this.countryDataViews.CM,
        // this.countryDataViews.CV,
        this.countryDataViews.CF,
        // this.countryDataViews.KM,
        this.countryDataViews.CD,
        this.countryDataViews.DJ,
        this.countryDataViews.EG,
        this.countryDataViews.GQ,
        this.countryDataViews.ER,
        this.countryDataViews.ET,
        this.countryDataViews.GA,
        this.countryDataViews.GM,
        this.countryDataViews.GH,
        this.countryDataViews.GN,
        this.countryDataViews.GW,
        this.countryDataViews.CI,
        this.countryDataViews.KE,
        this.countryDataViews.LS,
        this.countryDataViews.LR,
        this.countryDataViews.LY,
        this.countryDataViews.MG,
        this.countryDataViews.MW,
        this.countryDataViews.ML,
        this.countryDataViews.MR,
        // this.countryDataViews.MU,
        this.countryDataViews.MA,
        this.countryDataViews.MZ,
        this.countryDataViews.NA,
        // this.countryDataViews.NE,
        this.countryDataViews.NG,
        this.countryDataViews.CG,
        // this.countryDataViews.RE,
        this.countryDataViews.RW,
        // this.countryDataViews.SH,
        // this.countryDataViews.ST,
        this.countryDataViews.SN,
        // this.countryDataViews.SC,
        this.countryDataViews.SL,
        // this.countryDataViews.SO,
        this.countryDataViews.ZA,
        this.countryDataViews.SS,
        this.countryDataViews.SD,
        // this.countryDataViews.SZ,
        this.countryDataViews.TZ,
        this.countryDataViews.TG,
        this.countryDataViews.TN,
        this.countryDataViews.UG,
        this.countryDataViews.EH,
        this.countryDataViews.ZM,
        this.countryDataViews.ZW
      ]
    },
    getMaxVal () {
      let max = 0
      for (let i = 0; i < this.allCountryArrat.length; i++) {
        if (max < this.allCountryArrat[i]) {
          max = this.allCountryArrat[i]
        }
      }
      return max
    },
    postType: function () {
      return this.getPostType(this.campaign)
    }
  },
  async created () {
    let vm = this
    if (this.campaign.imagesKeys) this.getImahges()
    await firebaseDb.ref(firebaseRef.viewerTab).child(this.campaign.key)
      .once('value', function (snapshot) {
        snapshot.forEach(function (child) {
          // alert(child.val())
          vm.viewers.push(child.val())
        })
      })
    await firebaseDb.ref(firebaseRef.clickerTab).child(this.campaign.key)
      .once('value', function (snapshot) {
        snapshot.forEach(function (child) {
          // alert(child.val())
          vm.clickers.push(child.val())
        })
      })
    for (let i = 0; i < this.viewers.length; i++) {
      let ref = firebaseDb.ref(firebaseRef.users + vm.viewers[i])
      await ref.once('value').then(function (snapshot) {
        let countryCode = snapshot.child('remaUserInfos').child('countryCode').val()
        vm.allCountryUser.push(countryCode.substr(1, countryCode.length))
      })
    }
    for (let i = 0; i < this.clickers.length; i++) {
      let ref = firebaseDb.ref(firebaseRef.users + vm.clickers[i])
      await ref.once('value').then(function (snapshot) {
        let countryCode = snapshot.child('remaUserInfos').child('countryCode').val()
        vm.allClickersCountry.push(countryCode.substr(1, countryCode.length))
      })
    }
  },
  methods: {
    getCoof () {
      let sum = 0
      // eslint-disable-next-line no-unused-expressions
      for (let i = 0; i < this.campaign.paysCible.length; i++) {
        // eslint-disable-next-line no-const-assign
        sum += Math.round(this.countryMatchSms(this.campaign.paysCible[i].callingCodes))
      }
      return this.campaign.porteeProbable / sum
    },
    async getImahges () {
      if (this.getPostType(this.campaign) !== 'draft') {
        await firebaseStorage.getPostImage(this.campaign.key, this.campaign.imagesKeys[0])
          .then((url) => {
            this.image = url
            // alert(url)
          })
          .catch(() => {
            // alert('is-me')
          })
      } else {
        await firebaseStorage.getDraftPostImage(this.campaign.key, this.campaign.imagesKeys[0])
          .then((url) => {
            this.image = url
            // alert(url)
          })
          .catch((err) => {
            alert(err.toString())
          })
      }
    },
    maxValue () {
      return Math.max(this.allCountryArrat)
    },
    convertToEuro (cout, deviseMoney) {
      if (deviseMoney.text === '€') {
        return cout
      } else {
        return Math.round(cout / remaDefaultData.baseEuro)
      }
    },
    costPerViews (cost, views) {
      return isFinite((cost / views).toFixed(2)) ? (cost / views).toFixed(2) : 0.0
    },
    costPerClick (cost, click) {
      return isFinite((cost / click).toFixed(2)) ? (cost / click).toFixed(2) : 0.0
    },
    logRema  (value) {
      return 1.6
    },
    isPostEnd (dateDebut, nbrJour, time) {
      dateDebut = dateDebut.concat(' ' + time)
      return this.myEnd(dateDebut, nbrJour, time)
    },
    myEnd (dateDebut, nbrJour, time) {
      // alert(new Date(this.generateEndDate(dateDebut, time, nbrJour)).toLocaleString())
      if (new Date().getTime() >= this.generateEndDate(dateDebut, time, nbrJour)) {
        return true
      } else {
        return false
      }
    },
    generateEndDate (start, time, days) {
      // Days you want to subtract
      // J'avais fais plus 1 pour que le trucs finisse à 0 mais ils disent non don c j'ai enlevé le 1
      let jr = Number.parseInt(days)
      let dateDebut = start + time
      let date = moment(dateDebut, 'DD/MM/YYYY HH:mm')
      // alert(date)
      let end = new Date(new Date(date).getTime() + (jr * 24 * 60 * 60 * 1000)).getTime()
      return end
    },
    getSpeLabel (campaign) {
      if (this.getPostType(campaign) === 'draft') {
        return this.$t('update')
      } else {
        return this.$t('reUse')
      }
    },
    getSpeIcon (campaign) {
      if (this.getPostType(campaign) === 'draft') {
        return 'ic-modify'
      } else {
        return 'ic-action-use'
      }
    },
    getPostType (campaign) {
      let vm = this
      if (campaign.isActive && !campaign.postInfo.isDeleted && !campaign.postInfo.isSuspended && !vm.isPostEnd(campaign.postedAtDate, campaign.postDuree, campaign.postedAtTime)) {
        return 'progress'
      }
      if (!campaign.postInfo.isArchived && !campaign.postInfo.isDeleted && campaign.postInfo.isSuspended && !vm.isPostEnd(campaign.postedAtDate, campaign.postDuree, campaign.postedAtTime)) {
        return 'suspended'
      }
      if (!campaign.postInfo.isArchived && !campaign.postInfo.isDeleted && !campaign.postInfo.isSuspended && !campaign.postInfo.isEnd && !campaign.isActive && !campaign.isWaitValidation && !campaign.postInfo.isDraft) {
        return 'inactive'
      }
      if ((campaign.postInfo.isEnd && !campaign.postInfo.isDraft) || (vm.isPostEnd(campaign.postedAtDate, campaign.postDuree, campaign.postedAtTime) && !campaign.postInfo.isDraft)) {
        return 'ended'
      }
      if (!campaign.postInfo.isArchived && !campaign.postInfo.isDeleted && !campaign.postInfo.isSuspended && !campaign.postInfo.isEnd && campaign.isWaitValidation && !campaign.isActive) {
        return 'wait'
      }
      if (!campaign.postInfo.isArchived && !campaign.postInfo.isDeleted && !campaign.postInfo.isSuspended && !campaign.postInfo.isEnd && !campaign.isWaitValidation && !campaign.isActive && campaign.postInfo.isDraft) {
        return 'draft'
      }
    },
    postViewers: function (data) {
      let tab = []
      for (let data in this.viewerTab[0]) {
        if (data !== '.key') {
          tab.push(data)
        }
      }
      return tab
    },
    postClickers: function (data) {
      let tab = []
      for (let data in this.clickerTab[0]) {
        if (data !== '.key') {
          tab.push(data)
        }
      }
      return tab
    },
    emitCloseBlock (answer) {
      this.wantToSeePreview = answer
    },
    showPreview () {
      this.wantToSeePreview = !this.wantToSeePreview
      this.wantToSeeAction = false
    },
    pausedPost () {
      this.wantToSeeAction = false
      this.campaign.isVisible = false
      this.campaign.postInfo.isSuspended = true
      this.getPostType(this.campaign)
      firebaseDatabase.suspendPost(this.campaign, moment().format('DD/MM/YYYY HH:mm'))
    },
    countryMatch (code) {
      let value = 0
      for (let i = 0; i < this.allCountryUser.length; i++) {
        if (code === this.allCountryUser[i]) {
          value++
        }
      }
      if (code === '229') {
        this.countryDataViews.BJ = value
      }
      if (code === '213') {
        this.countryDataViews.DZ = value
      }
      if (code === '244') {
        this.countryDataViews.AO = value
      }
      if (code === '267') {
        this.countryDataViews.BW = value
      }
      if (code === '226') {
        this.countryDataViews.BF = value
      }
      if (code === '257') {
        this.countryDataViews.BI = value
      }
      if (code === '237') {
        this.countryDataViews.CM = value
      }
      if (code === '238') {
        this.countryDataViews.CV = value
      }
      if (code === '236') {
        this.countryDataViews.CF = value
      }
      if (code === '269') {
        this.countryDataViews.KM = value
      }
      if (code === '243') {
        this.countryDataViews.CD = value
      }
      if (code === '253') {
        this.countryDataViews.DJ = value
      }
      if (code === '20') {
        this.countryDataViews.EG = value
      }
      if (code === '240') {
        this.countryDataViews.GQ = value
      }
      if (code === '291') {
        this.countryDataViews.ER = value
      }
      if (code === '251') {
        this.countryDataViews.ET = value
      }
      if (code === '241') {
        this.countryDataViews.GA = value
      }
      if (code === '220') {
        this.countryDataViews.GM = value
      }
      if (code === '233') {
        this.countryDataViews.GH = value
      }
      if (code === '224') {
        this.countryDataViews.GN = value
      }
      if (code === '245') {
        this.countryDataViews.GW = value
      }
      if (code === '225') {
        this.countryDataViews.CI = value
      }
      if (code === '254') {
        this.countryDataViews.KE = value
      }
      if (code === '266') {
        this.countryDataViews.LS = value
      }
      if (code === '231') {
        this.countryDataViews.LR = value
      }
      if (code === '218') {
        this.countryDataViews.LY = value
      }
      if (code === '261') {
        this.countryDataViews.MG = value
      }
      if (code === '265') {
        this.countryDataViews.MW = value
      }
      if (code === '223') {
        this.countryDataViews.ML = value
      }
      if (code === '222') {
        this.countryDataViews.MR = value
      }
      if (code === '230') {
        this.countryDataViews.MU = value
      }
      if (code === '212') {
        this.countryDataViews.MA = value
      }
      if (code === '258') {
        this.countryDataViews.MZ = value
      }
      if (code === '264') {
        this.countryDataViews.NA = value
      }
      if (code === '227') {
        this.countryDataViews.NE = value
      }
      if (code === '234') {
        this.countryDataViews.NG = value
      }
      if (code === '242') {
        this.countryDataViews.CG = value
      }
      if (code === '262') {
        this.countryDataViews.RE = value
      }
      if (code === '250') {
        this.countryDataViews.RW = value
      }
      if (code === '290') {
        this.countryDataViews.SH = value
      }
      if (code === '239') {
        this.countryDataViews.ST = value
      }
      if (code === '221') {
        this.countryDataViews.SN = value
      }
      if (code === '248') {
        this.countryDataViews.SC = value
      }
      if (code === '232') {
        this.countryDataViews.SL = value
      }
      if (code === '252') {
        this.countryDataViews.SO = value
      }
      if (code === '27') {
        this.countryDataViews.ZA = value
      }
      if (code === '211') {
        this.countryDataViews.SS = value
      }
      if (code === '249') {
        this.countryDataViews.SD = value
      }
      if (code === '268') {
        this.countryDataViews.SZ = value
      }
      if (code === '255') {
        this.countryDataViews.TZ = value
      }
      if (code === '228') {
        this.countryDataViews.TG = value
      }
      if (code === '216') {
        this.countryDataViews.TN = value
      }
      if (code === '256') {
        this.countryDataViews.UG = value
      }
      if (code === '212') {
        this.countryDataViews.EH = value
      }
      if (code === '260') {
        this.countryDataViews.ZM = value
      }
      if (code === '263') {
        this.countryDataViews.ZW = value
      }
      return value
    },
    countryMatchSms (code) {
      let value = 0
      // alert(this.campaign.phoneArray[0].substr(1, 3))
      for (let i = 0; i < this.campaign.phoneArray.length; i++) {
        if (code === this.campaign.phoneArray[i].substr(1, 3)) {
          value++
        }
      }
      if (code === '229') {
        this.countryDataViews.BJ = Math.round(value * 2.6)
      }
      if (code === '213') {
        this.countryDataViews.DZ = Math.round(value * 2.6)
      }
      if (code === '244') {
        this.countryDataViews.AO = Math.round(value * 2.6)
      }
      if (code === '267') {
        this.countryDataViews.BW = Math.round(value * 2.6)
      }
      if (code === '226') {
        this.countryDataViews.BF = Math.round(value * 2.6)
      }
      if (code === '257') {
        this.countryDataViews.BI = Math.round(value * 2.6)
      }
      if (code === '237') {
        this.countryDataViews.CM = Math.round(value * 2.6)
      }
      if (code === '238') {
        this.countryDataViews.CV = Math.round(value * 2.6)
      }
      if (code === '236') {
        this.countryDataViews.CF = Math.round(value * 2.6)
      }
      if (code === '269') {
        this.countryDataViews.KM = Math.round(value * 2.6)
      }
      if (code === '243') {
        this.countryDataViews.CD = Math.round(value * 2.6)
      }
      if (code === '253') {
        this.countryDataViews.DJ = Math.round(value * 2.6)
      }
      if (code === '20') {
        this.countryDataViews.EG = Math.round(value * 2.6)
      }
      if (code === '240') {
        this.countryDataViews.GQ = Math.round(value * 2.6)
      }
      if (code === '291') {
        this.countryDataViews.ER = Math.round(value * 2.6)
      }
      if (code === '251') {
        this.countryDataViews.ET = Math.round(value * 2.6)
      }
      if (code === '241') {
        this.countryDataViews.GA = Math.round(value * 2.6)
      }
      if (code === '220') {
        this.countryDataViews.GM = Math.round(value * 2.6)
      }
      if (code === '233') {
        this.countryDataViews.GH = Math.round(value * 2.6)
      }
      if (code === '224') {
        this.countryDataViews.GN = Math.round(value * 2.6)
      }
      if (code === '245') {
        this.countryDataViews.GW = Math.round(value * 2.6)
      }
      if (code === '225') {
        this.countryDataViews.CI = Math.round(value * 2.6)
      }
      if (code === '254') {
        this.countryDataViews.KE = Math.round(value * 2.6)
      }
      if (code === '266') {
        this.countryDataViews.LS = Math.round(value * 2.6)
      }
      if (code === '231') {
        this.countryDataViews.LR = Math.round(value * 2.6)
      }
      if (code === '218') {
        this.countryDataViews.LY = Math.round(value * 2.6)
      }
      if (code === '261') {
        this.countryDataViews.MG = Math.round(value * 2.6)
      }
      if (code === '265') {
        this.countryDataViews.MW = Math.round(value * 2.6)
      }
      if (code === '223') {
        this.countryDataViews.ML = Math.round(value * 2.6)
      }
      if (code === '222') {
        this.countryDataViews.MR = Math.round(value * 2.6)
      }
      if (code === '230') {
        this.countryDataViews.MU = Math.round(value * 2.6)
      }
      if (code === '212') {
        this.countryDataViews.MA = Math.round(value * 2.6)
      }
      if (code === '258') {
        this.countryDataViews.MZ = Math.round(value * 2.6)
      }
      if (code === '264') {
        this.countryDataViews.NA = Math.round(value * 2.6)
      }
      if (code === '227') {
        this.countryDataViews.NE = Math.round(value * 2.6)
      }
      if (code === '234') {
        this.countryDataViews.NG = Math.round(value * 2.6)
      }
      if (code === '242') {
        this.countryDataViews.CG = Math.round(value * 2.6)
      }
      if (code === '262') {
        this.countryDataViews.RE = Math.round(value * 2.6)
      }
      if (code === '250') {
        this.countryDataViews.RW = Math.round(value * 2.6)
      }
      if (code === '290') {
        this.countryDataViews.SH = Math.round(value * 2.6)
      }
      if (code === '239') {
        this.countryDataViews.ST = Math.round(value * 2.6)
      }
      if (code === '221') {
        this.countryDataViews.SN = Math.round(value * 2.6)
      }
      if (code === '248') {
        this.countryDataViews.SC = Math.round(value * 2.6)
      }
      if (code === '232') {
        this.countryDataViews.SL = Math.round(value * 2.6)
      }
      if (code === '252') {
        this.countryDataViews.SO = Math.round(value * 2.6)
      }
      if (code === '27') {
        this.countryDataViews.ZA = Math.round(value * 2.6)
      }
      if (code === '211') {
        this.countryDataViews.SS = Math.round(value * 2.6)
      }
      if (code === '249') {
        this.countryDataViews.SD = Math.round(value * 2.6)
      }
      if (code === '268') {
        this.countryDataViews.SZ = Math.round(value * 2.6)
      }
      if (code === '255') {
        this.countryDataViews.TZ = Math.round(value * 2.6)
      }
      if (code === '228') {
        this.countryDataViews.TG = Math.round(value * 2.6)
      }
      if (code === '216') {
        this.countryDataViews.TN = Math.round(value * 2.6)
      }
      if (code === '256') {
        this.countryDataViews.UG = Math.round(value * 2.6)
      }
      if (code === '212') {
        this.countryDataViews.EH = Math.round(value * 2.6)
      }
      if (code === '260') {
        this.countryDataViews.ZM = Math.round(value * 2.6)
      }
      if (code === '263') {
        this.countryDataViews.ZW = Math.round(value * 2.6)
      }
      return value
    },
    countryMathClickers (code) {
      let value = 0
      for (let i = 0; i < this.allClickersCountry.length; i++) {
        if (code === this.allClickersCountry[i]) {
          value++
        }
      }
      return value
    },
    deletepost () {
      // this.$emit('deletePost', this.pos)
      this.campaign.isDelete = true
      this.campaign.isVisible = false
      this.campaign.isActive = false
      if (this.campaign.isInvalidate) {
        if (firebaseDatabase.deletePost('invalidedSms', this.campaign.key)) {
          this.$toast.error(this.$t('campagneDeleted'))
        }
      }
      if (this.getPostType(this.campaign) === 'progress' || this.getPostType(this.campaign) === 'suspended' || this.getPostType(this.campaign) === 'ended' || this.getPostType(this.campaign) === 'wait') {
        if (firebaseDatabase.deletePost('smsAdsFeeds', this.campaign.key)) {
          this.$toast.error(this.$t('campagneDeleted'))
        }
      }
      if (this.getPostType(this.campaign) === 'draft') {
        if (firebaseDatabase.deletePost('draftSmsFeed', this.campaign.key)) {
          this.$toast.error(this.$t('campagneDeleted'))
        }
      }
      this.wantToSeeAction = false
    },
    restartPaused () {
      this.campaign.isVisible = true
      this.campaign.postInfo.isSuspended = false
      this.getPostType(this.campaign)
      firebaseDb.ref('adsFeeds/mainFeeds/' + this.campaign.key + '/isVisible').set(true)
      firebaseDb.ref('adsFeeds/mainFeeds/' + this.campaign.key + '/postInfo/isSuspended').set(false)
      firebaseDb.ref('adsFeeds/mainFeeds/' + this.campaign.key + '/postInfo/isSuspendedAt').remove()
      this.wantToSeeAction = false
    },
    reUsePost () {
      // let vm = this
      let formatedUrl = this.campaign.key + '&type=' + this.getPostType(this.campaign)
      this.wantToSeeAction = false
      this.$store.dispatch('createPost', this.campaign)
      this.$router.replace('campaign-sms/' + formatedUrl)
    }
  },
  firebase () {
    return {
      viewerTab: firebaseDb.ref(firebaseRef.viewerTab).orderByKey().equalTo(this.campaign.key),
      viewCounting: firebaseDb.ref(firebaseRef.viewsCount).orderByKey().equalTo(this.campaign.key),
      clickerTab: firebaseDb.ref(firebaseRef.clickerTab).orderByKey().equalTo(this.campaign.key)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/mixin";
  .image-block {
    width: 17%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .list {
    z-index: 99;
    right: 0;
    background-color: white;
    margin-top: -11%;
  }
  .active-state {
    width: 6rem;
    background-color: transparent;
    color: $Green-Rema;
    border: 1px solid $Green-Rema;
  }
  .statusDraft {
    background-color: transparent;
    color: $light_gray2;
    border: 1px solid $light_gray2;
    width: 6rem;
  }
  .statusFinish {
    background-color: transparent;
    color: $red;
    border: 1px solid $red;
    width: 6rem;
  }
  .statusBreak {
    background-color: transparent;
    color: $orange;
    border: 1px solid $orange;
    width: 7rem;
  }
  .statusInvalid {
    background-color: transparent;
    color: $Black-Rema-text;
    border: 1px solid $Black-Rema-text;
    width: 6rem;
  }
  .statusWaiting {
    background-color: transparent;
    color: $light_gray;
    border: 1px solid $light_gray;
    width: 13rem;
  }
  .my-divider {
    height: .09rem;
    background-color: $light_gray2;
  }
  .camp-title{
    text-transform: capitalize;
  }
  .image-block {
    background-color: $Rema-Content-Background;
  }
  .icon-legend {
    height: 1.3rem; // 1.6
    width: 4rem;
  }
  .legend-section {
    margin-top: -20%;
  }
  .big-legend {
    background-color: $Green-Rema;
  }
  .small-legend {
    background-color: #c5edd4;
  }
  .none-legend {
    background-color: white;
  }
  .body {
    background-color: black !important;
  }
</style>
