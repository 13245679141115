import { firebaseDb } from '../main'
import { firebaseRef } from './firebaseRef'
import firebaseStorage from './firebaseStorage'
import firebase from 'firebase'
// import { key } from 'firebase-key'

export default {
  saveUser (data, uid, cb) {
    let userRef = firebaseDb.ref(firebaseRef.users + uid)
    let userInfos = {
      'active': true,
      'isAds': true,
      'online': false,
      'name': data.name,
      'fullname': data.name,
      'webSite': null,
      'tel': data.tel,
      'domaine': data.domaine,
      'description': data.description,
      'localisation': data.localisation,
      'postCount': 0,
      'adsInfos': {
        'email': data.email,
        'password': data.password
      },
      'uid': uid
      // 'owner': localStorage.ownerUid
    }
    if (userRef.set(userInfos)) {
      if (firebaseStorage.saveNewAvatar(uid, data.postAvatar)) {
        return true
      } else {
        return false
      }
    }
  },
  createCode (amount, client) {
    let randomstring = Math.random().toString(36).slice(-8)
    let code = {
      amount: 20000,
      isValid: true,
      uid: randomstring
    }
    firebaseDb.ref(firebaseRef.walletCode).child(randomstring).set(code).then((res) => {
      alert('success')
    }).catch((err) => {
      alert(err.toString())
    })
  },
  fundingWallet (amount, user, type, cb) {
    cb = arguments[arguments.length - 1]
    let vm = this
    let recharge = {
      type: '',
      date: new Date().getTime(),
      amount: amount
    }
    if (type === 'visa') {
      recharge.type = 'visa'
    } else {
      recharge.type = 'rema'
    }
    let ref = firebaseDb.ref(firebaseRef.wallets + user)
    firebaseDb.ref(firebaseRef.wallets + user).once('value', snapshot => {
      if (snapshot.exists()) {
        let current = snapshot.val()
        current.balance = current.balance + amount
        current.history.push(recharge)
        if (ref.update(current)) {
          // alert('im there')
          vm.cb(true)
        } else {
          vm.cb(false)
          //  alert('no there')
        }
      } else {
        var first = {
          balance: amount,
          history: []
        }
        first.history.push(recharge)
        if (ref.set(first)) {
          vm.cb(true)
        } else {
          vm.cb(false)
        }
      }
    })
  },
  saveAdsImages (key, images) {
    let paths = []
    for (let item in images) {
      // console.log('item' + images[item])
      paths.push(firebaseStorage.savePostImages(key, images[item]))
      // console.log('saveImages lessave' + item)
    }
    return paths
  },
  savePostFile (key, file) {
    return firebaseStorage.savePostFile(key, file)
  },
  createNewPost (data, dd) {
    // Here for save new ads in database
    dd = arguments[arguments.length - 1]
    let user = firebase.auth().currentUser
    let uid = user.uid
    let feedRef = firebaseDb.ref(firebaseRef.mainsAdsFeeds)
    let recoveryRef = firebaseDb.ref(firebaseRef.recoveryFeed)
    let newAdsKey = feedRef.push().key
    let imagesPath = this.saveAdsImages(newAdsKey, data.imagesPath)
    let attachImgKeys = this.saveAdsImages(newAdsKey, data.attachment.imagesUrl)
    console.log(data.attachment.imagesUrl)
    let butonn = {
      label: '',
      value: ''
    }
    let link = ''
    if (typeof data.postButton !== 'undefined') {
      butonn = data.postButton
    }
    if (typeof data.postButtonLink !== 'undefined') {
      link = data.postButtonLink
    }
    let postInRequest = {
      audience: data.audience || 0,
      isAdsPost: true,
      isActive: false,
      isVisible: true,
      isWaitValidation: true,
      author: uid,
      comments: [],
      imagesKeys: imagesPath,
      attachImgKeys: attachImgKeys,
      attachName: data.attachment.name,
      attachFileUrl: data.attachment.originalUrl,
      attachFileType: data.attachment.type,
      key: newAdsKey,
      likeCount: 0,
      liked: false,
      likers: [],
      instruted: false,
      instructifCount: 0,
      intructivers: [],
      braved: false,
      bravers: [],
      braveCount: 0,
      laughted: false,
      laugthCount: 0,
      laughters: [],
      postedAtDate: data.postStartDate,
      postedAtTime: data.postStartTime,
      textContent: data.postDescription,
      title: data.postTitre,
      postDuree: parseInt(data.nbrJour),
      postCout: data.postCout,
      deviseMoney: data.deviseSelected,
      professionsCible: data.professionSelected,
      professionCibleStr: data.professionCibleStr,
      cityCibleStr: data.cityCibleStr,
      cityCible: data.citySelected,
      othersCities: data.othersCities,
      paysCible: data.paysSelected,
      paysCibleStr: data.paysCibleStr,
      ageCible: data.ageSelected,
      ageCibleStr: data.ageCibleStr,
      sexeCible: data.sexe,
      viewCount: 0,
      viewers: [],
      isDelete: false,
      postButton: butonn,
      postButtonLink: link,
      postInfo: {
        isReposted: false,
        nbrRepost: 0,
        isSuspended: false,
        suspendedAt: null,
        isEnd: false,
        isArchived: false,
        archivedAt: null,
        isDeleted: false,
        deletedAt: null
      }
    }
    if (user != null) {
      if (feedRef.child(newAdsKey).set(postInRequest)) {
        if (attachImgKeys !== null && attachImgKeys.length > 0) {
          recoveryRef.child(newAdsKey).set(postInRequest)
        }
        firebaseDb.ref('adsFeeds-meta/viewCount/' + newAdsKey).set(0)
        firebaseDb.ref('adsFeeds-meta/clickCount/' + newAdsKey).set(0)
        console.log('Post ajouter')
        dd(true)
      } else dd(false)
    } else {
      // console.log('non effectuer post')
      dd(false)
    }
  },
  createNewSMSPost (data, dd) {
    // Here for save new ads in database
    dd = arguments[arguments.length - 1]
    let user = firebase.auth().currentUser
    let uid = user.uid
    let feedRef = firebaseDb.ref(firebaseRef.mainsSMSAdsFeeds)
    let newAdsKey = feedRef.push().key
    // let imagesPath = this.saveAdsImages
    let butonn = {
      label: '',
      value: ''
    }
    let link = ''
    if (typeof data.postButton !== 'undefined') {
      butonn = data.postButton
    }
    if (typeof data.postButtonLink !== 'undefined') {
      link = data.postButtonLink
    }
    let postInRequest = {
      isAdsPost: true,
      isActive: false,
      isVisible: true,
      isSMS: true,
      isAlreadySend: false,
      isWaitValidation: true,
      author: uid,
      comments: [],
      imagesKeys: [],
      key: newAdsKey,
      likeCount: 0,
      liked: false,
      likers: [],
      instruted: false,
      instructifCount: 0,
      intructivers: [],
      braved: false,
      bravers: [],
      braveCount: 0,
      laughted: false,
      laugthCount: 0,
      laughters: [],
      postedAtDate: data.postStartDate,
      postedAtTime: data.postStartTime,
      textContent: data.postDescription,
      porteeProbable: data.porteeProbable,
      title: data.postTitre,
      postDuree: parseInt(data.nbrJour),
      postCout: data.postCout,
      deviseMoney: data.deviseSelected,
      professionsCible: data.professionSelected,
      professionCibleStr: data.professionCibleStr,
      cityCibleStr: data.cityCibleStr,
      cityCible: data.citySelected,
      othersCities: data.othersCities,
      paysCible: data.paysSelected,
      paysCibleStr: data.paysCibleStr,
      ageCible: data.ageSelected,
      ageCibleStr: data.ageCibleStr,
      sexeCible: data.sexe,
      viewCount: 0,
      viewers: [],
      isDelete: false,
      postButton: butonn,
      postButtonLink: link,
      postInfo: {
        isReposted: false,
        nbrRepost: 0,
        isSuspended: false,
        suspendedAt: null,
        isEnd: false,
        isArchived: false,
        archivedAt: null,
        isDeleted: false,
        deletedAt: null
      },
      entrepriseName: data.entrepriseName,
      phoneArray: data.phoneArray
    }
    if (user != null) {
      if (feedRef.child(newAdsKey).set(postInRequest)) {
        firebaseDb.ref(`adsFeeds-meta/viewCount/${newAdsKey}`).set(0)
        firebaseDb.ref(`adsFeeds-meta/clickCount/${newAdsKey}`).set(0)
        console.log('Post ajouter')
        dd(true)
      } else dd(false)
    } else {
      // console.log('non effectuer post')
      dd(false)
    }
  },
  saveInDraftSMS (data, dd) {
    // Here for save new ads in database
    dd = arguments[arguments.length - 1]
    let draftedAt = this.timeFromTimeStampTwo(new Date().getTime())
    let user = firebase.auth().currentUser
    let uid = user.uid
    let feedRef = firebaseDb.ref(firebaseRef.draftSMSFeeds)
    let newAdsKey = feedRef.push().key
    // let imagesPath = this.saveAdsImages
    let butonn = {
      label: '',
      value: ''
    }
    let link = ''
    if (typeof data.postButton !== 'undefined') {
      butonn = data.postButton
    }
    if (typeof data.postButtonLink !== 'undefined') {
      link = data.postButtonLink
    }
    let postInRequest = {
      isAdsPost: true,
      isActive: false,
      isVisible: true,
      isSMS: true,
      isAlreadySend: false,
      isWaitValidation: false,
      author: uid,
      comments: [],
      imagesKeys: [],
      key: newAdsKey,
      likeCount: 0,
      liked: false,
      likers: [],
      instruted: false,
      instructifCount: 0,
      intructivers: [],
      braved: false,
      bravers: [],
      braveCount: 0,
      laughted: false,
      laugthCount: 0,
      laughters: [],
      postedAtDate: data.postStartDate,
      postedAtTime: data.postStartTime,
      textContent: data.postDescription,
      title: data.postTitre,
      postDuree: parseInt(data.nbrJour),
      postCout: data.postCout,
      deviseMoney: data.deviseSelected,
      professionsCible: data.professionSelected,
      professionCibleStr: data.professionCibleStr,
      porteeProbable: data.porteeProbable,
      cityCibleStr: data.cityCibleStr,
      cityCible: data.citySelected,
      othersCities: data.othersCities,
      paysCible: data.paysSelected,
      paysCibleStr: data.paysCibleStr,
      ageCible: data.ageSelected,
      ageCibleStr: data.ageCibleStr,
      sexeCible: data.sexe,
      viewCount: 0,
      viewers: [],
      isDelete: false,
      postButton: butonn,
      postButtonLink: link,
      postInfo: {
        isReposted: false,
        nbrRepost: 0,
        isSuspended: false,
        suspendedAt: null,
        isEnd: false,
        isArchived: false,
        archivedAt: null,
        isDeleted: false,
        deletedAt: null,
        isDraft: true,
        isDraftedAt: draftedAt
      },
      entrepriseName: data.entrepriseName,
      phoneArray: data.phoneArray
    }
    if (user != null) {
      if (feedRef.child(newAdsKey).set(postInRequest)) {
        firebaseDb.ref(`adsFeeds-meta/viewCount/${newAdsKey}`).set(0)
        firebaseDb.ref(`adsFeeds-meta/clickCount/${newAdsKey}`).set(0)
        console.log('Post ajouter')
        dd(true)
      } else dd(false)
    } else {
      // console.log('non effectuer post')
      dd(false)
    }
  },

  saveInDraft (data, dd) {
    // Here for save new ads in database
    dd = arguments[arguments.length - 1]
    let draftedAt = this.timeFromTimeStampTwo(new Date().getTime())
    let user = firebase.auth().currentUser
    let uid = user.uid
    let feedRef = firebaseDb.ref(firebaseRef.draftAds)
    let newAdsKey = feedRef.push().key
    let imagesPath = this.saveDraftAdsImages(newAdsKey, data.imagesPath)
    let attachImgKeys = this.saveAdsImages(newAdsKey, data.attachment.imagesUrl)
    let butonn = {
      label: '',
      value: ''
    }
    let link = ''
    if (typeof data.postButton !== 'undefined') {
      butonn = data.postButton
    }
    if (typeof data.postButtonLink !== 'undefined') {
      link = data.postButtonLink
    }
    let postInRequest = {
      audience: data.audience || 0,
      attachImgKeys: attachImgKeys,
      attachName: data.attachment.name,
      attachFileUrl: data.attachment.originalUrl,
      attachFileType: data.attachment.type,
      isAdsPost: true,
      isActive: false,
      isVisible: true,
      author: uid,
      isWaitValidation: false,
      comments: [],
      imagesKeys: imagesPath,
      key: newAdsKey,
      likeCount: 0,
      liked: false,
      likers: [],
      instruted: false,
      instructifCount: 0,
      intructivers: [],
      braved: false,
      bravers: [],
      braveCount: 0,
      laughted: false,
      laugthCount: 0,
      laughters: [],
      postedAtDate: data.postStartDate,
      postedAtTime: data.postStartTime,
      textContent: data.postDescription,
      title: data.postTitre,
      postDuree: data.nbrJour,
      postCout: data.postCout,
      deviseMoney: data.deviseSelected,
      professionsCible: data.professionSelected,
      professionCibleStr: data.professionCibleStr,
      cityCibleStr: data.cityCibleStr,
      paysCible: data.paysSelected,
      cityCible: data.citySelected,
      othersCities: data.othersCities,
      paysCibleStr: data.paysCibleStr,
      ageCible: data.ageSelected,
      ageCibleStr: data.ageCibleStr,
      sexeCible: data.sexe,
      viewCount: 0,
      viewers: [],
      postButton: butonn,
      postButtonLink: link,
      isDelete: false,
      postInfo: {
        isReposted: false,
        nbrRepost: 0,
        isSuspended: false,
        suspendedAt: null,
        isEnd: false,
        isArchived: false,
        archivedAt: null,
        isDeleted: false,
        deletedAt: null,
        isDraft: true,
        isDraftedAt: draftedAt
      }
    }
    if (user != null) {
      if (feedRef.child(newAdsKey).set(postInRequest)) {
        firebaseDb.ref(`adsFeeds-meta/viewCount/${newAdsKey}`).set(0)
        firebaseDb.ref(`adsFeeds-meta/clickCount/${newAdsKey}`).set(0)
        // alert(newAdsKey)
        console.log('Draft Save')
        dd(true)
      } else dd(false)
    } else {
      // console.log('non effectuer post')
      dd(false)
    }
  },
  saveDraftAdsImages (key, images) {
    let paths = []
    for (let item in images) {
      // console.log('item' + images[item])
      paths.push(firebaseStorage.savePostImagesInDraft(key, images[item]))
      // console.log('saveImages lessave' + item)
    }
    return paths
  },
  timeFromTimeStampTwo (timeStamp) {
    let obj = new Date(timeStamp)
    return obj.toLocaleDateString()
  },
  suspendPost (post, at) {
    firebaseDb.ref('adsFeeds/mainFeeds/' + post.key + '/isVisible').set(false)
    firebaseDb.ref('adsFeeds/mainFeeds/' + post.key + '/postInfo/isSuspended').set(true)
    firebaseDb.ref('adsFeeds/mainFeeds/' + post.key + '/postInfo/isSuspendedAt').set(at)
  },
  deletePost (ref, key) {
    firebaseDb.ref('adsFeeds/' + ref + '/' + key + '/isDelete').set(true)
    firebaseDb.ref('adsFeeds/' + ref + '/' + key + '/isVisible').set(false)
    firebaseDb.ref('adsFeeds/' + ref + '/' + key + '/isActive').set(false)
  }
}
